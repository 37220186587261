<template>
  <b-container :class="style['video-guide-container']">
    <p class="mt-5 title h1 font-weight-bold">
      How to prepare for the {{ type === "video" ? 'call' : 'stream' }}?
    </p>
    <b-row>
      <b-col
        xl="12"
        lg="12"
      >
        <b-row
          cols-sm="1"
          cols-md="2"
          cols-lg="2"
          cols-xl="3"
        >
          <b-col>
            <b-card :class="[style['shop-card'], 'mb-4', style['guide-card']]">
              <b-img
                :src="stress"
                :class="[style['shop-card-image'], 'mb-3']"
              />
              <p class="text-left mt-1 mb-5 h5 font-weight-bold">
                Don't stress
              </p>
              <p class="text-left mb-0 font-weight-light">
                Popz are experienced professionals who know how to talk with their fans. it's gonna be alright.
              </p>
            </b-card>
          </b-col>
          <b-col>
            <b-card :class="[style['shop-card'], 'mb-4', style['guide-card']]">
              <b-img
                :src="goal"
                :class="[style['shop-card-image'], 'mb-3']"
              />
              <p class="text-left mt-1 mb-5 h5 font-weight-bold">
                {{ type === "video" ? 'Set a goal for your meeting' : 'Be kind to other participants' }}
              </p>
              <p class="text-left mb-0 font-weight-light">
                {{ type === "video" ? "Friendly chat or an elevtor pitch? Set an objective and stick to it." : "Remember, all participants want to have fun. Treat others ho you'd like to be treated." }}
              </p>
            </b-card>
          </b-col>
          <b-col>
            <b-card :class="[style['shop-card'], 'mb-4', style['guide-card']]">
              <b-img
                :src="questions"
                :class="[style['shop-card-image'], 'mb-3']"
              />
              <p class="text-left mt-1 mb-5 h5 font-weight-bold">
                Prepare list of your questions
              </p>
              <p class="text-left mb-0 font-weight-light">
                Which questions are the most important to you?
              </p>
            </b-card>
          </b-col>
          <b-col>
            <b-card :class="[style['shop-card'], 'mb-4', style['guide-card']]">
              <b-img
                :src="technical"
                :class="[style['shop-card-image'], 'mb-3']"
              />
              <p class="text-left mt-1 mb-5 h5 font-weight-bold">
                Make sure the questions are no technical difficulties
              </p>
              <p class="text-left mb-0 font-weight-light">
                Charge your device, make sure you have stable internet connection and your microphone and camera works.
              </p>
            </b-card>
          </b-col>
          <b-col>
            <b-card :class="[style['shop-card'], 'mb-4', style['guide-card']]">
              <b-img
                :src="calendar"
                :class="[style['shop-card-image'], 'mb-3']"
              />
              <p class="text-left mt-1 mb-5 h5 font-weight-bold">
                Write the date in your calendar
              </p>
              <p class="text-left mb-0 font-weight-light">
                We will send you emails reminding you about the meeting, but set additional reminders,as this is the thing you really don't want to miss.
              </p>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import style from '../../styles/feed.module.scss';
import stress from '../../assets/imagesv2/icon_dont_stress.png';
import calendar from '../../assets/imagesv2/icon_calendar.png';
import goal from '../../assets/imagesv2/icon_goal.png';
import questions from '../../assets/imagesv2/icon_questions.png';
import technical from '../../assets/imagesv2/icon_technical_problem.png';
export default {
    name:'VideoGuide',
    data(){
        return {
            style,
            stress,
            calendar,
            goal,
            questions,
            technical,
            type: this.$route.params.type,
        };
    },

};
</script>